import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

import ArrowIcon from '../../../assets/icons/simple-arrow.svg'
import useClickOutsideAlerter from '../../../hooks/clickOutsideAlerterHook'
import useElementSize from '../../../hooks/useElementSize'
import useSizes from '../../../hooks/useSizes'
import stylesGeneral from '../../canvas/ChordCanvas.module.scss'
import Link from '../Link/Link'
import styles from './DropdownMenu.module.scss'

type Option = {
  name?: string
  key: number | string
  className?: string
  component?: string
  onClick?: (e: any) => void
  content?: React.ReactElement
  icon?: JSX.Element
  rightIcon?: JSX.Element
  sub?: Option[]
  subPosition?: 'left' | 'right'
  active?: boolean
  disabled?: boolean | null
  href?: string
  locale?: string
  blank?: boolean
  dontCloseOnSelect?: boolean
  renderNode?: (onClick: any) => JSX.Element
}

type Props = {
  options: Option[][]
  className?: string
  disabled?: boolean
  portal?: boolean
  mobileCollisionOffset?: number
  onMenuOpen?: (a: boolean) => void
  renderTrigger: (a: () => void, opened?: boolean) => React.ReactElement
  headerContent?: JSX.Element
  mobileView?: boolean | null
  generatorComponent?: boolean
  openSubOnHover?: boolean
  openOnHover?: boolean
  allowCustomPosition?: boolean
  canvasMode?: boolean
  menuOpened?: boolean
  id?: string
  clickOutsideDisabledIds?: string[]
}

const SubMenu = ({
  mobileView,
  options,
  open,
  onMenuClose,
  position = 'right',
}: {
  mobileView: boolean
  options: Option[]
  open: boolean
  onMenuClose: () => void
  position?: 'left' | 'right'
}) => (
  <div
    data-open={open}
    data-position={position}
    className={[styles.submenu, mobileView ? styles.subMenuMobileView : ''].join(' ')}
  >
    {options.map((option) => {
      const Component = option.component || (option.href || option.locale ? Link : 'button')

      const handleClick = (e: any) => {
        option.onClick && option.onClick(e)
        if (!option.dontCloseOnSelect) {
          onMenuClose()
        }
      }

      if (option.renderNode) return option.renderNode(handleClick)
      return (
        <Component
          href={option.href || ''}
          locale={option.locale}
          data-sub={true}
          key={option.key}
          className={`${styles.option} ${option.className || ''}`}
          onClick={handleClick}
        >
          {option.icon && <span>{option.icon}</span>}
          {option.content || option.name}
        </Component>
      )
    })}
  </div>
)

const DropdownMenu: React.FC<Props> = ({
  id,
  clickOutsideDisabledIds = [],
  options,
  renderTrigger,
  portal,
  onMenuOpen,
  openSubOnHover,
  openOnHover,
  className,
  headerContent,
  mobileView,
  generatorComponent = true,
  mobileCollisionOffset = 0,
  disabled,
  allowCustomPosition = false,
  canvasMode = false,
  menuOpened = false,
}) => {
  const { isMobile, isTablet } = useSizes()
  const [setRef, dropdownRef, { rect }] = useElementSize()

  const containerRef = useRef<HTMLDivElement>(null)
  const hoverRef = useRef()

  const [position, setPosition] = useState(0)
  const [menuOpen, setMenuOpen] = useState(mobileView)
  const [collisionVertOffset, setCollisionVertOffset] = useState(0)
  const [collisionHorOffset, setCollisionHorOffset] = useState(0)
  const [subMenuOpen, setSubMenuOpen] = useState<number | string | null>(null)

  useClickOutsideAlerter(dropdownRef, () => setMenuOpen(false), id)

  useEffect(() => {
    if (onMenuOpen) {
      onMenuOpen(menuOpen || false)
    }
    setSubMenuOpen(null)
  }, [menuOpen])
  useEffect(() => {
    if (menuOpened && !menuOpen) setMenuOpen(true)
  }, [menuOpened])
  useEffect(() => {
    const rect = dropdownRef?.getBoundingClientRect()
    if (rect && isMobile) {
      setCollisionVertOffset(Math.max(0, rect.bottom - window.innerHeight + mobileCollisionOffset))
    }
    if (rect) {
      setCollisionHorOffset(Math.max(0, rect.right - window.innerWidth + 10))
    }
  }, [rect?.bottom, rect?.right, menuOpen])

  useEffect(() => {
    if (dropdownRef && containerRef.current && isMobile) {
      const containerRect = containerRef.current.getBoundingClientRect()
      const dropdownRect = dropdownRef.getBoundingClientRect()

      if (window.innerWidth - containerRect.x > dropdownRect.width) {
        setPosition(0)
      } else {
        setPosition(-dropdownRect.width - containerRect.width - 4)
      }
    }
  }, [mobileView, menuOpen])

  const renderOption = (option: Option) => {
    const active = subMenuOpen === option.key
    const Component = option.component || (option.href || option.locale ? Link : 'button')

    const handleClick = (e: any) => {
      if (option.sub) {
        if (!openSubOnHover || isMobile) {
          setSubMenuOpen(subMenuOpen === option.key ? null : option.key)
        }
        return
      }
      option.onClick && option.onClick(e)
      if (!option.dontCloseOnSelect) {
        setMenuOpen(false)
      }
    }
    const onMouseEnter = () => {
      if (isMobile || !openSubOnHover) return
      setSubMenuOpen(option.key)
    }
    const onMouseLeave = () => {
      if (isMobile || !openSubOnHover) return
      setSubMenuOpen(null)
    }

    const renderOptionEl = () => {
      return (
        <Component
          className={[styles.option, option.className || '', option.disabled ? styles.disabled : ''].join(' ')}
          onClick={handleClick}
          href={option.href || ''}
          locale={option.locale}
          target={option.blank ? '_blank' : undefined}
          data-active={active || option.active || false}
        >
          {option.icon && <span>{option.icon}</span>}
          {option.content || option.name}
          {option.sub && (
            <span className={styles.optionArrow} data-open={active || option.active}>
              <ArrowIcon />
            </span>
          )}
          {option.rightIcon && <span className={styles.optionRightIcon}>{option.rightIcon}</span>}
        </Component>
      )
    }

    if (option.renderNode) return option.renderNode(handleClick)
    if (option.sub) {
      return (
        <div
          key={option.key}
          className={styles.optionContainer}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {renderOptionEl()}
          <SubMenu
            mobileView={mobileView || false}
            options={option.sub}
            open={subMenuOpen === option.key}
            onMenuClose={() => setMenuOpen(false)}
            position={option.subPosition}
          />
        </div>
      )
    }

    return <div key={option.key}>{renderOptionEl()}</div>
  }
  const renderMenu = () => (
    <div
      className={[
        styles.menuContainer,
        canvasMode ? stylesGeneral.noise : '',
        mobileView ? styles.menuMobileViewContainer : '',
        generatorComponent ? styles.dark : '',
        portal ? className : '',
      ].join(' ')}
      data-open={mobileView || menuOpen}
      data-portal={portal}
      data-disable-ids-click-outside-hook={clickOutsideDisabledIds}
      ref={setRef}
      style={{
        marginTop: mobileView ? 0 : -collisionVertOffset,
        marginLeft: mobileView || allowCustomPosition ? 0 : -collisionHorOffset,
        opacity: !rect?.x && mobileCollisionOffset > 0 && isMobile && !mobileView ? 0 : undefined,
        transform: `translateX(${allowCustomPosition ? position : 0}px)`,
        left: portal && !isMobile ? containerRef.current?.getBoundingClientRect().left : undefined,
        top: portal && !isMobile ? (containerRef.current?.getBoundingClientRect().top || 0) - 60 : undefined,
      }}
    >
      {headerContent || null}

      {options.map((optionsGroup, index) => (
        <>
          {index > 0 ? <div className={styles.divider} /> : null}
          {optionsGroup.map((option) => renderOption(option))}
        </>
      ))}
    </div>
  )
  return (
    <div
      // @ts-ignore
      ref={containerRef}
      id={id}
      className={classNames(styles.container, className, { [styles.dark]: generatorComponent })}
      onMouseEnter={
        openOnHover && !isTablet
          ? () => {
              setMenuOpen(true)
              clearInterval(hoverRef.current)
            }
          : undefined
      }
      onMouseLeave={
        openOnHover && !isTablet
          ? () => {
              // @ts-ignore
              hoverRef.current = setTimeout(() => setMenuOpen(false), 200)
            }
          : undefined
      }
    >
      {mobileView
        ? null
        : renderTrigger(() => {
            if (disabled) {
              return
            }
            // @ts-ignore
            setMenuOpen(!menuOpen)
          }, !!menuOpen)}
      {portal && !isMobile
        ? createPortal(renderMenu(), document.getElementById('__next') as HTMLDivElement)
        : renderMenu()}
    </div>
  )
}

export default DropdownMenu
