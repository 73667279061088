// @ts-ignore
import bufferToWav from 'audiobuffer-to-wav'
import * as Tone from 'tone'

import api from '../../api'
import { hjEvent } from '../hotjar'
import { getProgLengthInBars } from '../progUtils'
import { Chord, Prog, ProgPart } from '../types'
import { getPartDuration, sixteenthsToTicks } from './audioUtils'

export const generateFileName = (name: string, bpm: number, key: string, scale: string) =>
  `${name}_${bpm}bpm_${key}_${scale.slice(0, 3)}`

export const floatToInt16Pcm = (arrayBuffer: Float32Array) => {
  const input = new Float32Array(arrayBuffer)
  const output = new Int16Array(arrayBuffer.length)
  for (let i = 0; i < input.length; i++) {
    const s = Math.max(-1, Math.min(1, input[i]))
    output[i] = s < 0 ? s * 0x8000 : s * 0x7fff
  }
  return output
}

export const saveBlob = (blob: Blob, fileName: string) => {
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
}

export const floatBufferToBlobMp3 = (buffer: Tone.ToneAudioBuffer, name?: string) => {
  // @ts-ignore
  return import('lamejstmp').then(async (lamejs) => {
    const mp3Data = []
    const channels = buffer.numberOfChannels > 1 ? 2 : 1 // 2 for stereo, 1 for mono
    const sampleRate = 44100
    const kbps = 320
    const mp3encoder = new lamejs.Mp3Encoder(channels, sampleRate, kbps)

    let mp3Tmp
    if (channels === 2) {
      mp3Tmp = mp3encoder.encodeBuffer(
        floatToInt16Pcm(buffer.getChannelData(0)),
        floatToInt16Pcm(buffer.getChannelData(1)),
      )
    } else {
      mp3Tmp = mp3encoder.encodeBuffer(floatToInt16Pcm(buffer.getChannelData(0)))
    }
    mp3Data.push(mp3Tmp)
    mp3Tmp = mp3encoder.flush()
    mp3Data.push(mp3Tmp)
    hjEvent('event', 'export_mp3')
    const blob = new Blob(mp3Data, { type: 'audio/mp3' })
    saveBlob(blob, `${name || 'Progression'}.mp3`)
  })
}

export const floatBufferToBlobWav = async (buffer: Tone.ToneAudioBuffer, name?: string) => {
  // @ts-ignore
  const wav = bufferToWav(buffer)
  const blob = new window.Blob([new DataView(wav)], {
    type: 'audio/wav',
  })
  hjEvent('event', 'export_wav')
  saveBlob(blob, `${name || 'Progression'}.wav`)
}

export const getWavBlobFromFloatBuffer = async (buffer: Tone.ToneAudioBuffer, name?: string) => {
  // @ts-ignore
  const wav = bufferToWav(buffer)
  const blob = new window.Blob([new DataView(wav)], {
    type: 'audio/wav',
  })
  hjEvent('event', 'export_wav')
  return {
    blob,
    name: `${name || 'Progression'}.wav`,
  }
}

export const downloadMidiBlobFileFromProgCanvas = async (chords: Chord[], bpm: number) => {
  const blob = await api.post(
    '/api/export/canvas',
    {
      chords: JSON.stringify(chords),
      bpm,
    },
    { responseType: 'blob' },
  )
  hjEvent('event', 'export_midi')
  return {
    chordsBlob: blob,
    name: 'Midi',
  }
}

export const getProgDurationSeconds = (prog?: Prog | null) => {
  if (!prog) {
    return 0
  }
  const duration = getProgLengthInBars(prog)
  return Tone.Time(duration).toSeconds()
}

export const getProgDurationTicks = (part: ProgPart) => {
  const { duration, drumsDuration } = getPartDuration(part)

  return {
    duration: sixteenthsToTicks(duration),
    drumsDuration: sixteenthsToTicks(drumsDuration),
  }
}
